import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import './AdultStudy.css'

export default () => {
    const data = useStaticQuery(graphql`
    query SundaySchoolQuery{
      file(relativePath: { eq: "adultstudy.jpg" }) {
        childImageSharp {
          fluid (maxWidth:2000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
    `)
    return (<div>
        <div>
            <BackgroundImage fluid={data.file.childImageSharp.fluid} className='adultstudy_background'>
                <div className='adultstudy_title'>
                    <h1>成 人 主 日 學</h1>
                </div>
                <div className='box'>
                    <h1>「神为爱他的人所预备的、是眼睛未曾看见、耳朵未曾听见、人心也未曾想到的。」</h1>
                </div>
            </BackgroundImage>
        </div>
    </div>
    )
}