import { Drawer, Button, Row, Col } from 'antd';
import React, { useState } from 'react';
import './SundayStudySeminar.css'
import teacherseminar_tobereplaced from '../../images/teacherseminar_tobereplaced.jpg'
import greenfield from '../../images/greenfield.jpg'

export default () => {
class App extends React.Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div className="site-drawer-render-in-current-wrapper">
          <div className='form_container'>
                
                <div className='form_container'>
                    <Row>
                        <Col span={18} offset={2}>成人主日学教师研讨会</Col>
                        <Col span={2}>
                            <Button type="link" onClick={this.showDrawer}>图片抽屉</Button>
                        </Col>
                        <Col span={2}></Col>
                    </Row>
                    
                    <div className = 'drawer-cover-img'>
                    <img src={greenfield} alt='greenfield' ></img>
                    </div>
                </div>
            </div>
        <Drawer
          title="教师研讨会快照"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          getContainer={false}
          style={{ position: 'absolute' }}
        >
          <div className='form_container'>
          <img src={teacherseminar_tobereplaced} alt='teacherseminar_tobereplaced' ></img>
          </div>
        </Drawer>
      </div>
    );
  }
}


    return(
        <App />
    )
}