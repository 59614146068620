import React, { useState } from 'react';
import { Table, Radio, Divider, Row } from 'antd';

export default () => {
    const columns = [
        {
            title: '課程主題',
            dataIndex: 'coursename',
            // render: text => <a>{text}</a>,
        },
        {
            title: '講員',
            dataIndex: 'teacher',
        },
        {
            title: '教室或Zoom鏈接',
            dataIndex: 'room',
            // render: text => <a>{text}</a>,
        },
        {
            title: '時間',
            dataIndex: 'duration',
        },
    ];
    const data = [
        {
            key: '1',
            coursename: 'How now shall we live?',
            teacher: '朱兆明弟兄',
            room: <a href='https://us02web.zoom.us/j/82376859051'>Zoom ID 密碼：378192</a>,
            duration: '每周六10:30，03/2021-09/2021',
            status: 'expired',
        },
        // {
        //     key: '2',
        //     coursename: '基要真理',
        //     teacher: '沈信权',
        //     room: <a href='https://us02web.zoom.us/j/250518046'>Zoom ID 密码：378192</a>,
        //     duration: '',
        //     status: 'disabled',
        // },
        {
            key: '3',
            coursename: '十二小先知書',
            teacher: '鄺耀興弟兄',
            room: <a href='https://us02web.zoom.us/j/250518046'>Zoom ID 密碼：378192</a>,
            duration: '每週日11:30，02/2021-08/2021',
            status: 'expired',
        },
        {
            key: '4',
            coursename: '威斯敏斯特小要理問答',
            teacher: '沈信權牧師',
            room: <a href='https://us02web.zoom.us/j/250518046'>Zoom ID 密碼：378192</a>,
            duration: '每週六15:00，06/2021-12/2021',
            status: 'active',
        },
        {
            key: '2',
            coursename: '以弗所書',
            teacher: '蕭玉剛弟兄',
            room: <a href='https://us02web.zoom.us/j/250518046'>Zoom ID 密碼：378192</a>,
            duration: '每週日11:30，09/2021-12/2021',
            status: 'active',
        },
    ]; // rowSelection object indicates the need for row selection

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.status === 'expired',
            // Column configuration not to be checked
            coursename: record.coursename,
            hidden: record.status === 'disabled',
        }),
    };

    const CourseTable = () => {
        const [selectionType, setSelectionType] = useState('checkbox');
        return (
            <div>
                {/* 
        <Radio.Group
          onChange={({ target: { value } }) => {
            setSelectionType(value);
          }}
          value={selectionType}
        >
          
          <Radio value="checkbox">Checkbox</Radio>
          <Radio value="radio">Radio</Radio>
          <Radio value="editabletable">Editable Table</Radio>
          
        </Radio.Group>
        */}
                <Row><p></p></Row>
                <Table

                    // rowSelection={{      
                    //     type: selectionType,   
                    //     type: true,...rowSelection,    
                    //   }}   

                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
            </div>
        );
    };

    return (
        <CourseTable />
    )

}