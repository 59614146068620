import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Divider } from 'antd';
import FileUploaderComponent from '../SundaySchool/FileUploaderComponent'

export default (props) => {
  const originData = props.data;
  const dataIndex2TitleMapping = {
    // 'key' : '表格行键（调试）',
    // '_id' : '数据库标识符（调试）',
    'subject': '课程主题',
    'lecturer': '讲员',
    'classroom': '教室',
    'lectureNotesLink': '课件',
    'startDate': '开课日期',
    'archived': '已结束',
  };
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `请填 ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  const EditableTable = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState(originData);
    const [changed, setChanged] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);

    const isEditing = record => record.key === editingKey;

    const reload = () => {
      setLoading(true);
      fetch(`${process.env.SERVER_API_URL}/course_list/`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          const responseFromServer = resultData['courseInfo'];
          const dataForTable = responseFromServer.map(entry => {
            return { ...entry, key: entry["_id"], lectureNotesLink: <FileUploaderComponent /> }
          });
          setData(dataForTable);
        })
        .then(() => {
          setLoading(false);
          setChanged(false);
          console.log(`Curriculum reloaded.`);
        });
    }
    // useEffect(reload(), []);

    const edit = record => {
      form.setFieldsValue({
        // subject: '',
        //upload:,
        ...record,
      });
      setEditingKey(record.key);
    };

    const cancel = () => {
      const newData = [...data];
      const index = newData.findIndex(item => item.key === "new_rowkey");
      if (index > -1) {
        console.log(`Removing a newly added row from the table of ${newData.length} rows`);
        newData.splice(index, 1);
        setData(newData);
      }
      setEditingKey('');
    };

    const remove = key => {
      const newData = [...data];
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: key })
      };
      fetch(`${process.env.SERVER_API_URL}/course_list/deleteCourse`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          if (result.status === 'success') {
            setData(newData.filter(item => item.key !== key));
            setChanged(true);
          }
        });
      setEditingKey('');
    };

    const add = () => {
      const newData = {
        key: 'new_rowkey',
        subject: '',
        lecturer: '',
        classroom: '',
        lectureNotesLink: 0,
        archived: 0
      };
      form.setFieldsValue({
        subject: '',
        lecturer: '',
        classroom: '',
        // ...record,
      });
      setData([newData, ...data]);
      setEditingKey(newData['key']);
    };

    const save = async key => {
      try {
        const row = await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex(item => key === item.key);
        const item = newData[index];
        if (index > -1) {
          newData.splice(index, 1, { ...item, ...row });
          if ("_id" in item) {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ ...row, "_id": item["_id"] })
            };
            fetch(`${process.env.SERVER_API_URL}/course_list/editCourse`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result);
                if (result.status === 'success') {
                  setData(newData);
                  setChanged(true);
                }
              });
          } else {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ ...row })
            };
            fetch(`${process.env.SERVER_API_URL}/course_list/addCourse`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result);
                if (result.status === 'success') {
                  setData(newData);
                  setChanged(true);
                }
              });
          }
          setEditingKey('');
        } else {
          newData.push({ ...row, ...item });
          // setData(newData);
          setEditingKey('');
        }
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };
    const columns = Object.keys(dataIndex2TitleMapping).map(key => ({
      title: dataIndex2TitleMapping[key],
      dataIndex: key,
      // width: '25%',
      editable: key === 'subject' || key === 'classroom' || key === 'lecturer',
      render: (text, _) => { if (key === 'subject') return <a> {text}</a>; else return <span> {text} </span>; }
    }));
    // console.log(columns);
    columns.push(
      {
        title: '改动',
        dataIndex: 'action',
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <a
                // href="javascript:;"
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                保存
            </a>
              <Popconfirm title="确认删除？" onConfirm={() => remove(record.key)} okText="是" cancelText="否，继续编辑">
                <a style={{
                  marginRight: 8,
                }}>删除</a>
              </Popconfirm>
              <Popconfirm title="确认取消？" onConfirm={cancel} okText="是，取消编辑" cancelText="否，继续编辑">
                <a style={{
                  marginRight: 8,
                }}>取消</a>
              </Popconfirm>
            </span>
          ) : (
              <span>
                <a disabled={editingKey !== ''} onClick={() => edit(record)}>
                  编辑
          </a>
                <Popconfirm title="确认删除？" onConfirm={() => remove(record.key)} okText="是" cancelText="否，继续编辑">
                  <a disabled={editingKey !== ''} style={{
                    marginLeft: 8,
                  }}>删除</a>
                </Popconfirm>
              </span>
            );
        },
      },
    );
    const mergedColumns = columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
    return (
      <div>
        <Form form={form} component={false}>
          <Button
            onClick={add}
            type="primary"
            style={{
              marginBottom: 16,
            }}
          >
            添加课程
        </Button>
          <Button
            onClick={reload}
            type="primary"
            disabled={editingKey !== ''}
            loading={loading}
            style={{
              marginBottom: 16,
              marginLeft: 8
            }}
          >
            刷新
        </Button>
          <span style={{ marginLeft: 8 }}>
            {changed ? '表格已更新，请刷新。' : ''}
          </span>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            loading={loading}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
          />
        </Form>
      </div>
    );
  };

  return (<EditableTable />);

}