import React from 'react'
import Navbar from '../components/NavBar/NavBar'
import AdultStudy from '../components/SundaySchool/AdultStudy'
import AdultStudyGoalForm from '../components/SundaySchool/AdultStudyGoalForm'
import GlobalFooter from '../components/Footer/GlobalFooter'

export default () => {
    return (
      <div>
        <div>
          <Navbar />
        </div>
        <div>
          <AdultStudy />
        </div>
        <div>
          <AdultStudyGoalForm />
        </div>
        <div>
          <GlobalFooter />
        </div>
      </div>
      )
    }