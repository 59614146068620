import React, { useState, useEffect } from "react";
//import {Modal, Form, Input, Button, Select, DatePicker, Checkbox} from "antd";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Col, Row, Drawer, List, Avatar, Divider } from "antd";
import CardBackground1 from "../../images/homepage_pic1.jpg";
import CardBackground2 from "../../images/homepage_pic2.jpg";
import CardBackground3 from "../../images/homepage_pic3.jpg";
import CourseTable from "../SundaySchool/CourseTable";
import CourseTable2 from "../SundaySchool/CourseTable2";
import SundayStudySeminar from "../SundaySchool/SundayStudySeminar";
import EditableCourseTable from "../SundaySchool/EditableCourseTable";
import EditableCourseTable2 from "../SundaySchool/EditableCourseTable2";
import FileUploaderComponent from "./FileUploaderComponent";
import "./AdultStudyGoalForm.css";

/*
const { Option } = Select;
const axios = require('axios');
*/
const { Dragger } = Upload;

export default () => {
    const [courseInfo, setCourseInfo] = useState([]);
    const [courseInfoStorage, setCourseInfoStorage] = useState([]);
    const [courseStatusChecked, setCourseStatusChecked] = useState([0]);
    const [userRole, setUserRole] = useState(false);

    // useEffect(() => {getInitData();}, [userRole]);

    function getInitData() {
        fetch(`${process.env.SERVER_API_URL}/course_list/`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                const responseFromServer = resultData['courseInfo'];
                console.log(responseFromServer);
                setCourseInfo(responseFromServer);
                setCourseInfoStorage(responseFromServer);
            });
    }

    function switchUserRole() {
        setUserRole(!userRole);
        console.log(userRole);
    }

    function TableToRender(props) {
        // if (!courseInfo.length) getInitData();
        const isAdmin = props.isAdmin;
        const data = props.data;

        if (isAdmin) {
            const originData = data.map(entry => {
                return { ...entry, key: entry["_id"], lectureNotesLink: <FileUploaderComponent /> }
            });
            return <EditableCourseTable2 data={originData} rowKey="_id" />;
        }
        return <CourseTable2 data={data} rowKey="_id" />;
    }

    return (
        <div>
            <div className="invitation">
                <h1>
                請你和我們一起來領受神在我們前面的路上所預備的、遠超過我們所求的、更大、更美的祝福。
                </h1> <h1>
                成人主日學課程目標：整全而有系統地門徒培訓模式
                </h1>
            </div>
            <div className="desktop">
                <Row align="center">
                    <Col span={5} offset={0}>
                        <div className="form_container">
                            <img src={CardBackground1} alt="CardBackground1"></img>
                            <div className="container_text">
                                <h1>異象</h1>
                               <p>認識真理 活出基督</p>
                                <p>成為器皿 實現使命</p> 
                            </div>
                        </div>
                    </Col>
                    <Col span={5} offset={1}>
                        <div className="form_container">
                            <img src={CardBackground2} alt="CardBackground2"></img>
                            <div className="container_text">
                                <h1>指導思想</h1>
                                <p>聖經為基礎</p>
                                <p>基督為中心</p>
                                <p>聖靈為導師</p>
                                <p>生命為主題</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={5} offset={1}>
                        <div className="form_container">
                            <img src={CardBackground3} alt="CardBackground3"></img>
                            <div className="container_text">
                                <h1>課程規劃</h1>
                                <p>基礎裝備系列</p>
                                <p>靈命塑造系列</p>
                                <p>實現使命系列</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mobile">
                <Row justify="space-around">
                    <Col span={16} offset={0}>
                        <div className="form_container">
                            <img src={CardBackground1} alt="CardBackground1"></img>
                            <div className="container_text">
                                <h1>異象</h1>
                                <p>認識真理 活出基督</p>
                                <p>成為器皿 實現使命</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={16} offset={0}>
                        <div className="form_container">
                            <img src={CardBackground2} alt="CardBackground2"></img>
                            <div className="container_text">
                                <h1>指導思想</h1>
                                <p>聖經為基礎</p>
                                <p>基督為中心</p>
                                <p>聖靈為導師</p>
                                <p>生命為主題</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={16} offset={0}>
                        <div className="form_container">
                            <img src={CardBackground3} alt="CardBackground3"></img>
                            <div className="container_text">
                                <h1>課程規劃</h1>
                                <p>基礎裝備系列</p>
                                <p>靈命塑造系列</p>
                                <p>實現使命系列</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    <div className="invitation">
                        <h1>
                        2021年度成人主日學課程安排
                        </h1>
                    </div>
                    <CourseTable />
                </Col>
                <Col span={4}></Col>
            </Row>
            {/* <Divider /> */}

            {/*
            <Row align="middle">
                <Col span={20} offset={2}>
                    <div className="form_title3">
                        <SundayStudySeminar/>
                    </div>
                </Col>
            </Row>
            <Row>
                <p></p>
            </Row>

            */}

            {/*<Divider />*/}
            {/*<Row>*/}
            {/*  <Col span={2}></Col>*/}
            {/*  <Col span={20}>*/}
            {/*    <EditableCourseTable />*/}
            {/*  </Col>*/}
            {/*  <Col span={2}></Col>*/}
            {/*</Row>*/}
            {/* <Row>
                <Col span={2}></Col>
                <Button type="primary" onClick={switchUserRole}> 切换用户身份 </Button>
            </Row>
            <Divider/>
            <Row>
                <Col span={2}></Col>
                <Col span={18}>
                    <TableToRender isAdmin={userRole} data={courseInfoStorage}/>
                </Col>
                <Col span={4}></Col>
            </Row> */}
        </div>
    );
};
